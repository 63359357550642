// roboto is not included in this template
// and therefore we disable the setup here.
$enable-roboto-webfont: false !default;

//// VARIABLES
// ... add your variables here
$ic-green: #254D2A;
$font-gray: #242424;
$font-black: #3D3D3D;
$ic-orange: #FAA000;
$btn-box-shadow: #00000029;
$ic-gray-line: #707070;
$font-beige-30: #FFFBF0;


//// IMPORTS
// Import barceloneta files from node_modules --load-path=node_modules
@import "@plone/plonetheme-barceloneta-base/scss/barceloneta.scss";


//// STYLES
// ... add your styles here

//// DEFAULT ---start---
//html {
//  font-size: 14px !important;
//}

main {
  margin-bottom: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  padding-top: 0 !important;
  overflow-x: hidden;
}

img {
  border-radius: 4px;
}

.row{
  margin-left: 0!important;
  margin-right: 0!important;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

#portal-column-content {
  //padding-top: 4.5rem;
}

//@media (max-width: 768px) {
//  #portal-column-content {
//    padding-top: 5.5rem !important;
//  }
//}

@media (min-width: 992px) {
  body.col-one.col-content #portal-column-content {
    flex: 0 0 auto;
    width: 100%;
  }

  .mt-lg-ic10 {
    margin-top: 15rem;
  }

  .mw-lg-43-ic-karriere {
    max-width: 43.75rem;
  }
}

.section-top-padding {
  padding-top: 3rem;
}


.ic-container-fluid-pt {
  padding-top: 6.25rem;
}

.ic-container-fluid-pb {
  padding-bottom: 6.25rem;
}

.ic-container-fluid-mb {
  margin-bottom: 6.25rem;
}

.ic-container-fluid-mt {
  margin-top: 6.25rem;
}

.btn-arrow-hover {
  font: normal normal 500 1.313rem/1.875rem Roboto;
  letter-spacing: 0;
  color: #254d2a;
}

.btn-arrow-hover:hover {
  color: $ic-orange !important;
}

//// DEFAULT ---end---


//// CUSTOM-RICHTEXT-SECTION ---start---
.bg-img-hover-normal:hover {
  mix-blend-mode: normal !important;
}

.companies-logo-wrapper:hover {
  border-color: #000000 !important;
}

//// CUSTOM-RICHTEXT-SECTION ---end---


//// FOOTER ---start---
#footer {
  //margin-top: 6.25rem;
  background: rgba(214, 214, 214, 0.3) 0% 0% no-repeat padding-box;
  padding-top: 4rem;
  padding-bottom: 2.5rem;
}

.flex-footer {
  display: flex;
  justify-content: center;
}

#horizontal-line {
  width: 80%;
  border-top: 1px solid $ic-gray-line;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.flex-footer a {
  color: $ic-gray-line !important;
}

.footer-ic-cr {
  font: normal normal normal 1.125rem/1.313rem Roboto;
  letter-spacing: 0.034rem;
  color: #707070;
  opacity: 1;
}

.footer-ic-di {
  font: normal normal 500 1.438rem/1.688rem Roboto;
  letter-spacing: 0.043rem;
  color: #707070;
  opacity: 1;
  text-decoration: none;
}

.footer-ic-di:hover {
  color: #254D2A;
}

.footer-ic-nav-link {
  font: normal normal 500 1.438rem/1.688rem Roboto;
  letter-spacing: 0.043rem;
  color: #242424;
  opacity: 1;
}

.footer-ic-nav-link:hover {
  color: #254D2A;
}

.footer-ic-nav-link-kontakt {
  color: #FAA000;
}

.footer-ic-sub-li {
  text-align: left;
  font: normal normal normal 1.125rem/1.313rem Roboto;
  letter-spacing: 0.082rem;
  color: $ic-gray-line;
}

//// FOOTER ---end---

//// NAVBAR ---start---
.smart-scroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.scrolled-down {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.scrolled-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.navbar {
  background: white;
  border-bottom: solid 1px rgba(112, 112, 112, 0.2);
  padding: 1rem;
  width: 100vw;
}

.ic-nav-logo {
  max-width: 12rem !important;
}

#navbarResponsive {
  justify-content: right;
  right: 0;
}

#navbarResponsive .nav-link {
  text-align: center;
  font: normal normal 500 1.313rem/1.438rem Roboto;
  letter-spacing: 0.039rem;
  color: #707070;
  opacity: 1;
  padding-left: 2rem;
  padding-right: 2rem;
}

#navbarResponsive .nav-item:hover {
  color: #254D2A;
}

#navbarResponsive .nav-link-kontakt {
  text-decoration: none;
  text-align: center;
  font: normal normal 500 1.313rem/1.438rem Roboto;
  letter-spacing: 0.043rem;
  color: #FAA000;
  opacity: 1;
  border: 3px solid #FAA000;
  border-radius: 8px;
  //padding: 0.8rem 4.75rem 0.8rem 1.875rem;
}

#navbarResponsive .nav-link-kontakt:hover {
  color: #FFFFFF !important;
  background: #FAA000 0 0 no-repeat padding-box !important;
}

#navbarResponsive .nav-link:hover {
  color: #254D2A;
}

.mini-navigation-link {
  text-decoration: none;
  padding: 0 1.5rem 0 1.5rem;
  text-align: left;
  font: normal normal normal 1.125rem/2.125rem Roboto;
  letter-spacing: 0.011rem;
  color: #242424;
  opacity: 0.7;
}

.mini-navigation-link:hover {
  color: $ic-green;
}

.nav-sub-link:hover {
  color: $ic-green;
}

.sub-navbar {
  position: fixed;
  margin-top: 5rem;
  z-index: 1000;
}

.index-sub-navbar-wrapper {
  text-align: end;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 3px 5px 8px #00000029;
  padding: 0.5rem 13.6rem 0.5rem 6.25rem;
}

.leistungen-sub-navbar-wrapper {
  text-align: end;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 3px 5px 8px #00000029;
  padding: 0.5rem 13.6rem 0.5rem 6.25rem;
}

.ueberuns-sub-navbar-wrapper {
  text-align: end;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 3px 5px 8px #00000029;
  padding: .5rem 13.6rem .5rem 6.25rem
}

.mini-navigation-link-pr-0 {
  padding-right: 0 !important;
}

//// NAVBAR ---end---


//// HERO-SECTION ---start---
.masthead {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.hero-sub-heading {
  font: normal normal normal 1.625rem/1.875rem Ubuntu;
  letter-spacing: 0;
  opacity: 1;
  color: $ic-green;
  margin-bottom: 1.25rem;
}

.hero-heading {
  text-align: left;
  font: normal normal bold 4.125rem/4.875rem Ubuntu;
  letter-spacing: 0;
  opacity: 1;
  color: $ic-green;
  margin-bottom: 2.5rem;
}

.hero-description {
  text-align: left;
  font: normal normal normal 1.5rem/2.25rem Roboto;
  letter-spacing: 0.015rem;
  opacity: 1;
  color: $font-gray;
  transition-duration: 0.4s;
  margin-bottom: 5rem;
}

.hero-calltoaction-btn {
  background: $ic-orange 0 0 no-repeat padding-box;
  border: 2px solid $ic-orange;
  box-shadow: 8px 8px 12px $btn-box-shadow;
  border-radius: 8px;
  opacity: 1;
  padding: 1.625rem 2.5rem;
}

.hero-calltoaction-btn:hover {
  background: $ic-green 0 0 no-repeat padding-box;
  border-color: $ic-green;
}

.hero-calltoaction-chover:hover {
  background: transparent !important;
  border-color: #FAA000 !important;
}

.hero-calltoaction-text {
  text-align: center;
  font: normal normal bold 1.5rem/1.75rem Roboto;
  letter-spacing: 0.046rem;
  color: #FFFFFF;
  opacity: 1;
}

.hero-projectfocus-heading {
  text-align: left;
  font: normal normal bold 1.625rem/1.875rem Ubuntu;
  letter-spacing: 0.117rem;
  color: #FFFFFF;
  opacity: 1;
}

.hero-prjectfocus-context-text {
  text-align: left;
  font: normal normal normal 1.5rem/2.25rem Roboto;
  letter-spacing: 0.015rem;
  color: #242424;
  opacity: 1;
  margin-bottom: 0;
}

.hero-projectfocus-content-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-bottom: 0.75rem;
  padding: 0.55rem 1rem 0.5rem 1rem;
  border-radius: 4px;
}

.hero-sec-content-container {
  padding-top: 6.25rem;
  height: 100%;
}

//// HERO-SECTION ---end---

//// SIMPLE_SECTION ---start---
.simple-sec-heading {
  text-align: center;
  font: normal normal bold 3rem/3.438rem Ubuntu;
  letter-spacing: 0;
  color: $ic-green;
  opacity: 1;
  margin-bottom: 2.5rem;
}

.simple-sec-heading-mb-0 {
  margin-bottom: 0 !important;
}

.simple-sec-description {
  text-align: center;
  font: normal normal normal 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: $font-black;
  opacity: 1;
}

//// SIMPLE_SECTION ---end---

//// TWO-COLUMNS-WITH-RICHTEXT ---start---
.two-col-richtext-sec-heading {
  text-align: center;
  font: normal normal bold 3.5rem/4.063rem Ubuntu;
  letter-spacing: 0;
  color: $ic-green;
  opacity: 1;
}

.two-col-richtext-box {
  border: 3px solid #707070;
  border-radius: 8px;
  display: table-cell;
  padding: 4.375rem 6.25rem;
  max-width: 40rem;
}

.two-col-richtext-box-h1 {
  text-align: left;
  font: normal normal normal 2rem/2.375rem Ubuntu;
  letter-spacing: 0;
  color: #254d2a;
  margin-bottom: 2.5rem;
}

.two-col-richtext-box-p {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
  margin-bottom: 1.375rem;
}

.two-col-richtext-box2-h1 {
  font: normal normal bold 9.375rem/8.75rem Ubuntu;
  letter-spacing: 0px;
  color: #254d2a;
  opacity: 1;
  margin-bottom: 1.5rem;
}

.two-col-richtext-box2-h2 {
  font: normal normal 300 2.5rem/3rem Ubuntu;
  letter-spacing: 0px;
  color: #254d2a;
  opacity: 1;
  margin-bottom: 1.125rem;
}

.two-col-richtext-box2-h3 {
  font: normal normal normal 1.5rem/1.75rem Ubuntu;
  letter-spacing: 0px;
  color: #254d2a;
  opacity: 1;
}

.row-table {
  display: table;
  width: 100%;
}

//// TWO-COLUMNS-WITH-RICHTEXT ---end---


//// CALL-TO-ACTION-SECTION ---start---
.calltoaction-sec-pb {
  padding-bottom: 3.75rem;
}

.calltoaction-sec-pt {
  padding-top: 3.75rem;
}

.calltoaction-sec-heading {
  text-align: center;
  font: italic normal 500 1.75rem/2.5rem Roboto;
  color: $font-beige-30;
  opacity: 1;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.calltoaction-sec-calltoaction-btn {
  background: $ic-orange 0 0 no-repeat padding-box;
  box-shadow: 8px 8px 12px #00000029;
  border: 2px solid $ic-orange;
  border-radius: 8px;
  padding: 1.75rem 3.75rem;
}

.calltoaction-sec-calltoaction-btn:hover {
  background: transparent;
}

.calltoaction-sec-calltoaction-text {
  text-align: center;
  font: normal normal bold 1.313rem/1.5rem Roboto;
  letter-spacing: 0.041rem;
  color: #FFFFFF;
  opacity: 1;
}

.calltoaction-sec-container-fluid {
  background: transparent linear-gradient(180deg, #426641 0%, #254D2A 30%, #19341C 70%, #162E19 100%) 0% 0% no-repeat padding-box;
}

.calltoaction-sec-check-list-content {
  text-align: left;
  font: normal normal normal 1.75rem/2.875rem Roboto;
  color: #FFFBF0;
  margin-right: 1rem;
}

.calltoaction-sec-check-list-content-icon {
  font-size: 2.75rem;
  color: #FFFBF0;
}

.calltoaction-btn-ic-gr {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 12px #00000029;
  border: 2px solid #254D2A;
  border-radius: 8px;
  padding: 1.75rem;
}

.calltoaction-btn-ic-gr:hover {
  background: #254D2A 0 0 no-repeat padding-box;
}

.calltoaction-btn-ic-gr:hover .calltoaction-text-ic-gr {
  color: #FFFFFF;
}

.calltoaction-text-ic-gr {
  text-align: center;
  font: normal normal bold 1.313rem/1.5rem Roboto;
  letter-spacing: 0.041rem;
  color: #254D2A;
  opacity: 1;
}

#calltoaction-checklist-table > tbody:nth-child(1) > tr:nth-child(1) > th, #calltoaction-checklist-table > tbody:nth-child(1) > tr:nth-child(2) > th {
  padding-bottom: 0rem;
}

//// CALL-TO-ACTION-SECTION ---end---


//// TWO-IMAGE-COLUMNS ---start---
.two-image-columns-col-img {
  background: $ic-gray-line 0 0 no-repeat padding-box;
  mix-blend-mode: luminosity;
  opacity: 1;
  transition: 0.4s;
}

.two-image-columns-col-img:hover {
  mix-blend-mode: normal;
}

.two-image-columns-col-heading {
  text-align: center;
  font: normal normal 500 2rem/3rem Roboto;
  letter-spacing: 0;
  color: $font-black;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 1rem;
}

.two-image-columns-col-description {
  text-align: center;
  font: normal normal normal 2rem/3rem Roboto;
  letter-spacing: 0;
  color: $ic-gray-line;
  opacity: 1;
}

//// TWO-IMAGE-COLUMNS ---end---

/// THREE-COLUMNS-CARDS ---start---
.threecolcards-sec-heading {
  text-align: center;
  font: normal normal bold 3.5rem/4.063rem Ubuntu;
  letter-spacing: 0;
  color: $ic-green;
  opacity: 1;
}

.box-container {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 5px 5px 8px #00000029;
  border-radius: 8px;
}

.card-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 1;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.box:hover .zoom {
  transform: scale(1.2);
}

.box:hover .green-overlay {
  background: rgba(37, 77, 42, 0.4) !important;
}

.box:hover .content-item {
  background: rgba(37, 77, 42, 1) !important;
  -webkit-transition: background .2s ease;
  -moz-transition: background .2s ease;
  -o-transition: background .2s ease;
  transition: background .2s ease;
}

.zoom {
  z-index: -20;
  background-size: cover !important;
  height: 25rem !important;
  position: relative;
  transition: 0.2s !important;
}

.green-overlay {
  background: rgba(37, 77, 42, 0.7);
  transition: 0.2s;
  border-radius: 4px;
}

.threecolcards-card-subheading {
  text-align: left;
  font: normal normal 300 1.125rem/1.875rem Roboto;
  letter-spacing: 0.023rem;
  color: $font-beige-30;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 0.688rem;
}

.threecolcards-card-heading {
  text-align: left;
  font: normal normal bold 1.688rem/2.25rem Roboto;
  letter-spacing: 0.034rem;
  color: $font-beige-30;
  opacity: 1;
  margin-bottom: 1rem;
}

.threecolcards-card-desc {
  text-align: left;
  font: normal normal normal 1.125rem/1.875rem Roboto;
  letter-spacing: 0.011rem;
  color: $font-beige-30;
  opacity: 1;
}

/// THREE-COLUMNS-CARDS ---end---


/// FULL-WIDTH-BACKGROUND-IMAGE ---start---
.fw-background-img-pt {
  padding-top: 3.375rem;
}

.fw-background-img-pb {
  padding-bottom: 3.375rem;
}

.fw-background-img-heading {
  text-align: center;
  font: normal normal bold 3rem/3.75rem Ubuntu;
  letter-spacing: 0;
  color: $font-beige-30;
  opacity: 1;
}

/// FULL-WIDTH-BACKGROUND-IMAGE ---end---

//// INFO-SECTION ---start---
.info-section-container {
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
}

.info-section-p {
  text-align: left;
  font: normal normal 500 1.313rem/2.25rem Roboto;
  letter-spacing: 0.013rem;
  color: $font-gray;
  margin-bottom: 0;
}

.info-hover-link:hover * {
  color: #254D2A !important;
}

//// INFO-SECTION ---end---


//// TESTIMONIAL-SECTION ---start---
.testimonial-section-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #D6D6D6;
  border-radius: 8px;
  opacity: 1;
  border: none;
  padding: 5rem 7.5rem;
  max-width: 72.5rem;
  width: 100%;
}

.testimonial-section-comment-p {
  text-align: left;
  font: italic normal 500 1.5rem/2.375rem Roboto;
  color: #254D2A;
}

.testimonial-section-hr {
  width: 10%;
  background: #D6D6D6 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 3px !important;
}

.testimonial-section-customer-name-position {
  text-align: left;
  font: normal normal normal 1.125rem/2.25rem Roboto;
  letter-spacing: 0.011rem;
  color: #707070;
  opacity: 1;
}

.testimonial-section-img {
  mix-blend-mode: luminosity;
  border-radius: 8px;
}

.testimonial-section-img:hover {
  mix-blend-mode: normal;
}

.testimonial-section-customer-name {
  color: $font-gray;
  font-weight: 500;
}

.testimonial-section-container-pt {
  padding-top: 5rem;
}

.testimonial-section-container-pb {
  padding-bottom: 5rem;
}

//// TESTIMONIAL-SECTION ---end---


//// BREADCRUMBS-ARROW-STEPS ---start---
$blue-gray: #34495e;
$blue-gray-darken: #2c3e50;
$blue: #3498db;
$blue-darken: #2980b9;
$green: #1abc9c;
$green-darken: #16a085;

.breadcrumb-arrow-steps {
  list-style: none;
  display: inline-block;
  padding: 0;
  width: 100%;
  margin-bottom: -6px;

  .icon {
    font-size: 14px;
  }

  li {
    float: left;

    .last-arrow-step {
      margin-right: 20px !important;
    }

    //Arrow Step 1
    .arrow-step-1 {
      display: block;
      background: $ic-green;
      text-decoration: none;
      position: relative;
      height: 160px;
      line-height: 40px;
      padding: 0 10px 0 5px;
      margin-right: 23px;
      text-align: center;
      font: normal normal bold 1.5rem/2rem Roboto;
      letter-spacing: 0.03rem;
      color: #FAFAFA;
      opacity: 1;
      padding-top: 3.75rem;
    }

    .arrow-step-1 {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border: 0 solid $ic-green;
        border-width: 80px 10px;
        width: 0;
        height: 0;
      }

      &:before {
        left: -20px;
        border-left-color: transparent;
      }

      &:after {
        left: 100%;
        border-color: transparent;
        border-left-color: $ic-green;
      }

    }

    //Arrow Step 2
    .arrow-step-2 {
      display: block;
      background: $ic-green;
      text-decoration: none;
      position: relative;
      height: 160px;
      line-height: 40px;
      padding: 0 10px 0 5px;
      margin-right: 23px;
      text-align: center;
      font: normal normal bold 1.5rem/2rem Roboto;
      letter-spacing: 0.03rem;
      color: #FAFAFA;
      opacity: 1;
      padding-top: 3.75rem;
    }

    .arrow-step-2 {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border: 0 solid $ic-green;
        border-width: 80px 10px;
        width: 0;
        height: 0;
      }

      &:before {
        left: -20px;
        border-left-color: transparent;
      }

      &:after {
        left: 100%;
        border-color: transparent;
        border-left-color: $ic-green;
      }

    }

    //Arrow Step 3
    .arrow-step-3 {
      display: block;
      background: $ic-green;
      text-decoration: none;
      position: relative;
      height: 160px;
      line-height: 40px;
      padding: 0 10px 0 5px;
      margin-right: 23px;
      text-align: center;
      font: normal normal bold 1.5rem/2rem Roboto;
      letter-spacing: 0.03rem;
      color: #FAFAFA;
      opacity: 1;
      padding-top: 3.75rem;
    }

    .arrow-step-3 {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border: 0 solid $ic-green;
        border-width: 80px 10px;
        width: 0;
        height: 0;
      }

      &:before {
        left: -20px;
        border-left-color: transparent;
      }

      &:after {
        left: 100%;
        border-color: transparent;
        border-left-color: $ic-green;
      }

    }

    &:first-child {
      a {
        //padding-left:15px;
        //@include border-radius(4px 0 0 4px);
        &:before {
          //border:none;
        }
      }
    }

    &:last-child {
      a {
        //padding-right:15px;
        //@include border-radius(0 4px 4px 0);
        &:after {
          //border:none;
        }
      }
    }

  }
}

//// BREADCRUMBS-ARROW-STEPS ---end---

//// EXPERTISE ---start---
.expertise-pub-p {
  text-align: left;
  font: normal normal normal 1.313rem/2.125rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
}

.expertise-a-hover-ic-or {
  text-decoration: underline;
  font-weight: 500;
  color: #254d2a;
}

.expertise-a-hover-ic-or:hover {
  color: #FAA000 !important;
}

.expertise-mitg-h3 {
  text-align: left;
  font: normal normal bold 1.313rem/1.813rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
  opacity: 1;
}

.expertise-mitg-p {
  text-align: left;
  font: normal normal normal 1.125rem/1.75rem Roboto;
  letter-spacing: 0.023rem;
  color: #242424;
  opacity: 1;
}

.expertise-mitg-hr {
  width: 100%;
  background: #d6d6d6 0 0 no-repeat padding-box;
  opacity: 1;
  height: 2px;
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;
}

.expertise-mitg-img-wrapper {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 12px #D6D6D6;
  border-radius: 8px;
  padding: 3.125rem 5.25rem;
  display: flex;
  min-height: 15rem;
}

.expertise-mitg-img {
  max-height: 10rem;
  border-radius: 8px;
}

.expertise-fkproj-h3 {
  text-align: left;
  font: normal normal normal 1.65rem/2rem Ubuntu;
  color: #254d2a;
  margin-bottom: 1.563rem;
}

.expertise-fkproj-p {
  text-align: left;
  font: normal normal normal 1.125rem/1.75rem Roboto;
  letter-spacing: 0.011rem;
  color: #242424;
}

.expertise-fkproj-box {
  padding: 4.375rem 4.688rem;
  height: 100%;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 12px #242424;
  border-radius: 8px;
}

.expertise-buch-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 12px #D6D6D6;
  border-radius: 8px;
  opacity: 1;
}

.expertise-buch-box-ptrbl {
  padding: 3.125rem 4.063rem;
}

.expertise-buch-container-mb {
  margin-bottom: 2.5rem;
}

.expertise-buch-h3 {
  text-align: left;
  font: normal normal bold 1.5rem/1.875rem Roboto;
  letter-spacing: 0.03rem;
  color: #242424;
  opacity: 1;
}

.expertise-buch-p {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  letter-spacing: 0.03rem;
  color: #242424;
}

.expertise-buch-img-wrapper {
  box-shadow: 0 0.188rem 0.375rem #00000029;
  max-height: 15.625rem;
}

.expertise-abarb-img {
  border-radius: 4px;
  max-height: 12.5rem;
}

//// EXPERTISE ---end---


//// DATENSCHUTZ/IMPRESSUM ---start---

.di-h1 {
  text-align: left;
  font: normal normal bold 3rem/3.438rem Ubuntu;
  letter-spacing: 0;
  color: $ic-green;
  opacity: 1;
}


.di-h2 {
  text-align: left;
  font: normal normal medium 2rem/2.375rem Roboto;
  letter-spacing: 0;
  color: $ic-gray-line;
  opacity: 1;
}

.di-p {
  text-align: left;
  font: normal normal normal 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: $font-gray;
}

.di-a {
  text-align: left;
  text-decoration: underline;
  font: normal normal medium 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: $font-gray;
}

.di-p-small {
  text-align: left;
  font: normal normal normal 1rem/1.5rem Roboto;
  letter-spacing: 0.01rem;
  color: $font-gray;
  opacity: 1;
}

//// DATENSCHUTZ/IMPRESSUM ---end---

//// KARRIERE ---start---
.karriere-btn-hover:hover {
  color: $ic-orange !important;
}

.karriere-btn-hover {
  color: #254d2a;
}

.green-overlay-karriere {
  background: rgba(37, 77, 42, 0.7);
  transition: 0.2s;
  border-radius: 4px;
}

.zoom-karriere {
  z-index: -20;
  background-size: cover !important;
  height: 25rem !important;
  position: relative;
}

.card-content-karriere {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  position: absolute;
  text-align: center;
  z-index: 1;
  padding: 0 3rem 0 3rem;
  bottom: 3rem;
}

.card-title-karriere {
  text-align: center;
  font: normal normal bold 1.313rem/1.625rem Roboto;
  letter-spacing: 0.026rem;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 1.25rem;
}

.card-text-karriere {
  text-align: center;
  font: normal normal normal 1rem/1.5rem Roboto;
  letter-spacing: 0.02rem;
  color: #FFFFFF;
  opacity: 1;
}

.card-karriere {
  height: 100%;
}

.card-karriere:hover .card-body-karriere {
  background: transparent linear-gradient(180deg, #162E19 0%, #19341C 30%, #254D2A 70%, #426641 100%) 0% 0% no-repeat padding-box;
}

.card-karriere img {
  height: 50%;
  border-radius: unset;
}

.card-body-karriere {
  padding: 2.5rem 2.188rem 1.875rem 2.188rem;
  background: transparent linear-gradient(180deg, #242424 0%, #575757 100%) 0% 0% no-repeat padding-box;
  opacity: 0.85;
}

.karriere-jobs-h3 {
  text-align: center;
  font: normal normal bold 1.313rem/1.875rem Roboto;
  color: #254d2a;
  margin-bottom: 1.25rem;
}

.karriere-jobs-p {
  text-align: center;
  font: normal normal normal 1rem/1.5rem Roboto;
  color: #242424;
  margin-bottom: 1.25rem;
}

.karriere-jobs-btn1 {
  background: #FAA000 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 12px #00000029;
  border: 3px solid #FAA000;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 1rem/1.188rem Roboto;
  letter-spacing: 0.031rem;
  color: #ffffff;
  padding: 1.375rem 1.875rem;
  text-decoration: none;
  width: 60%;
}

.karriere-jobs-btn1:hover {
  background-color: #254D2A;
  border-color: #254D2A;
  color: #FFFFFF;
}

.karriere-jobs-btn2 {
  border: 3px solid #FAA000;
  box-shadow: 8px 8px 12px #00000029;
  border-radius: 8px;
  text-align: center;
  font: normal normal bold 1rem/1.188rem Roboto;
  letter-spacing: 0.031rem;
  color: #FAA000;
  padding: 1.375rem 1.875rem;
  margin-top: 1.25rem;
  text-decoration: none;
  width: 60%;
}

.karriere-jobs-btn2:hover {
  background-color: #254D2A;
  border-color: #254D2A;
  color: #FFFFFF;
}

.karriere-jobs-btn-group {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.karriere-glide-slide {
  height: auto;
}

.karriere-glide-slide-container {
  display: flex;
  flex-direction: column;
  height: 100% !important;
  padding: 3.75rem 2.5rem;
}

.karriere-card-img-top {
  object-fit: cover;
  object-position: 50% 20%;
  max-height: 18.125rem;
}

.karriere-btn-hover:hover {
  color: #FAA000 !important;
}

//// KARRIERE ---end---

//// KONTAKT ---start---
.kontakt-calendar-steps-n {
  text-align: left;
  font: normal normal 500 2.5rem/1.625rem Roboto;
  color: #242424;
  opacity: 1;
}

.kontakt-calendar-steps-t {
  text-align: left;
  font: normal normal 500 1rem/1.25rem Roboto;
  letter-spacing: 0.031rem;
  color: #242424;
  opacity: 1;
  margin-left: 0.625rem;
}

.kontakt-steps-n {
  margin-right: 1rem;
  font: normal normal 500 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: #254D2A;
  margin-bottom: 0;
}

.kontakt-steps-t {
  font: normal normal 500 1.313rem/2rem Roboto;
  letter-spacing: 0.013rem;
  color: #242424;
  opacity: 1;
  margin-bottom: 0;
}

span.required {
  display: none;
}

#kontakt-form {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 21px #2424245F;
  border-radius: 8px;
  opacity: 1;
  padding: 3.75rem 4.875rem;
  min-height: 50rem;
}

#kontakt-form h3 {
  text-align: center;
  font: normal normal bold 2rem/2.375rem Roboto;
  letter-spacing: 0.062rem;
  color: #707070;
  opacity: 1;
  margin-bottom: 1.875rem;
}

#formfield-form-widgets-comments {
  height: 9rem;
}

#form-widgets-comments {
  height: 100%;
}

#form {
  display: flex;
  flex-wrap: wrap;
}

#formfield-form-widgets-unternehmen, #formfield-form-widgets-vorname {
  width: 47%;
  margin-right: 6%;
}

#formfield-form-widgets-anrede, #formfield-form-widgets-nachname {
  width: 47%;
}

#formfield-form-widgets-replyto, #formfield-form-widgets-telefonnummer, #formfield-form-widgets-comments {
  width: 100%;
}

.form-control, .form-select {
  padding: .975rem 1.25rem !important;
  background-color: #F0F0F0 !important;
  border-radius: 8px !important;
  border: none !important;
}

#form > div > input, #form > div > select, #form > div > textarea {
  text-align: left;
  font: normal normal normal 1.313rem/1.5rem Roboto;
  letter-spacing: 0.019rem;
  color: #707070;
  opacity: 1;
}

#form > div > textarea {
  resize: none;
}

#form-widgets-vor_ort {
  text-align: left;
  font: normal normal normal 1.313rem/1.5rem Roboto;
  letter-spacing: 0.013rem;
  color: #242424;
  opacity: 1;
}

#formfield-form-widgets-datenschutz {
  text-align: left;
  font: normal normal normal 1.125rem/1.5rem Roboto;
  letter-spacing: 0.013rem;
  color: #242424;
  opacity: 1;
}

.formControls {
  width: 100%;
  text-align: center;
}

#form-buttons-submit {
  background: #FAA000 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 12px #00000029;
  border-radius: 8px;
  opacity: 1;
  border: none;
  font: normal normal bold 1.5rem/1.8rem Roboto;
  letter-spacing: 0.046rem;
  padding: 1.1rem 3.1rem;
}

#form-buttons-submit:hover {
  background-color: #254d2a;
}

.formControls {
  margin-bottom: 0 !important;
  margin-top: 2rem !important;
}

.kontakt-sec-steps {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 1rem 1.2rem;
}

.kontakt-sec-steps-mb {
  margin-bottom: 1rem;
}

.kontakt-sec-step-number {
  text-align: left;
  font: normal normal bold 2rem/2.25rem Ubuntu;
  letter-spacing: 0.02rem;
  color: #707070;
}

.kontakt-sec-step-content {
  text-align: left;
  font: normal normal normal 1.5rem/2.25rem Roboto;
  letter-spacing: 0.015rem;
  color: #242424;
  opacity: 1;
}

.kontakt-sec-step-link {
  text-decoration: underline;
  color: $ic-green;
}

.kontakt-sec-step-link-hover:hover {
  cursor: pointer;
  color: $ic-orange !important;
}

//// KONTAKT ---end---


/// INDEX ---start---
.col-mw-index {
  max-width: 35rem;
}

.index-comp-3col-p {
  text-align: center;
  font: normal normal 500 1.313rem/1.875rem Roboto;
  letter-spacing: 0.013rem;
  color: #242424;
}

.index-comp-3col-h3 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font: normal normal bold 1.313rem/1.625rem Roboto;
  letter-spacing: 0.026rem;
  color: #fffbf0;
}

.index-comp-3col-name-btn-box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
  transition: .5s ease;
  background: transparent linear-gradient(360deg, rgba(36, 36, 36, 0.85) 0%, rgba(87, 87, 87, 0.85) 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  display: table;
  text-align: center;
  z-index: 20;
  padding: 1rem;
}

.index-comp-3col-box-container {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;
  z-index: 20;
}

.index-comp-3col-box:hover .zoom {
  transform: scale(1.2);
}

.index-comp-3col-box:hover .index-comp-3col-name-btn-box {
  background: transparent linear-gradient(360deg, rgba(22, 46, 25, 0.85) 0%, rgba(25, 52, 28, 0.85) 30%, rgba(37, 77, 42, 0.85) 70%, rgba(66, 102, 65, 0.85) 100%) 0% 0% no-repeat padding-box;
}

.logo-carousel-glide-slide {
  height: 6.25rem;
}

.custom-glide-arrow-left {
  left: -5.5rem !important;
}

.custom-glide-arrow-right {
  right: -5.5rem !important;
}

.glide__slide {
  background: white 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.glide__arrow {
  color: #FFFBF0;
  font-size: 2rem;
  background: #faa000 0 0 no-repeat padding-box;
  border-color: #faa000;
}

.glide__arrow:hover {
  background-color: #254d2a;
  border-color: #254d2a;
  }

#stellenangebote .glide__slide {
  background: #F0F0F0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

#stellenangebote .glide__arrows{
  display: flex;
justify-content: center;
gap: 1rem;
}

#stellenangebote .glide__arrow--left{
left: 0;
top: unset;
bottom: 0;
position: relative;
margin-top: 3rem;
display: inline-block;
}
#stellenangebote .glide__arrow--right{
right: 0;
top: unset;
bottom: 0;
position: relative;
margin-top: 3rem;
display: inline-block;
}

.index-ss-c-desc {
  color: #707070;
  font: normal normal normal 2.5rem/3.25rem Roboto;
  letter-spacing: 0.05rem;
}

.index-um-3col-box {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 5px 5px 8px #00000029;
  border-radius: 8px;
  padding: 2.75rem 4.063rem;
}

.index-um-3col-h1 {
  text-align: center;
  font: normal normal 500 2rem/2.375rem Ubuntu;
  color: #254d2a;
  margin-bottom: 1.875rem;
}

.index-um-3col-h2 {
  text-align: left;
  font: normal normal 500 1.313rem/2.125rem Roboto;
  color: #242424;
  margin-bottom: 1.875rem;
}

.index-um-3col-p {
  text-align: center;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  color: #242424;
}

.index-um-3col-li {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  color: #242424;
  margin-bottom: 1.25rem;
}

.index-vision-name {
  text-align: center;
  font: normal normal 500 2rem/3rem Roboto;
  color: #ffffff;
  margin-bottom: 0.75rem;
}

.index-vision-position {
  text-align: center;
  font: normal normal normal 1.313rem/3rem Roboto;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 2.5rem;
}

.index-vision-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
}

.index-vision-image-overlay-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.index-vision-two-image-col-hover {
  mix-blend-mode: luminosity;
}

.index-vision-two-image-col-hover:hover {
  mix-blend-mode: revert;
}

.index-stat-h1 {
  text-align: center;
  font: normal normal bold 5rem/5.875rem Roboto;
  color: #faa000;
  margin-bottom: 1rem;
}

.index-stat-p {
  text-align: center;
  font: normal normal 500 1.5rem/2.125rem Roboto;
  color: #707070;
}

.index-foer-h3 {
  text-align: left;
  font: normal normal bold 1.313rem/1.813rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
  opacity: 1;
}

.index-foer-desc {
  text-align: left;
  font: normal normal normal 1.125rem/1.75rem Roboto;
  letter-spacing: 0.023rem;
  color: #242424;
  opacity: 1;
}

.index-foer-hr {
  width: 100%;
  background: #d6d6d6 0 0 no-repeat padding-box;
  opacity: 1;
  height: 2px;
}

/// INDEX ---end---


/// UEBERUNS ---start---

.custom-r-green-h3 {
  font-weight: bold !important;
  color: #254D2A;
}

.ueber-uns-events-mp {
  text-align: left;
  font: normal normal bold 1.5rem/1.75rem Roboto;
  letter-spacing: 0.06rem;
  color: #707070;
  opacity: 1;
  margin-bottom: 0.625rem;
}

.ueber-uns-events-tp {
  text-align: left;
  font: normal normal 300 1.313rem/1rem Roboto;
  color: #707070;
  opacity: 1;
}

.ueber-uns-events-h3 {
  text-align: left;
  font: normal normal bold 1.5rem/1.75rem Roboto;
  letter-spacing: 0.03rem;
  color: #242424;
  opacity: 1;
}

.ueber-uns-events-p1 {
  text-align: left;
  font: normal normal 500 1.313rem/1.75rem Roboto;
  letter-spacing: 0.026rem;
  color: #707070;
  opacity: 1;
  margin-bottom: 1.875rem;
}

.ueber-uns-events-p2 {
  text-align: left;
  font: normal normal normal 1.313rem/1.5rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
  opacity: 1;
  margin-bottom: 1.5rem;
}

.ueber-uns-events-hr {
  width: 100%;
  border: 1px solid #D6D6D6;
  opacity: 1;
}

.ueber-uns-events-a {
  margin-left: 3rem;
}

.ueber-uns-events-box {
  padding: 2.5rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 12px #D6D6D6;
  border-radius: 8px;
  opacity: 1;
}

.ueber-uns-events-img-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 12px #D6D6D6;
  border-radius: 8px;
  opacity: 1;
  padding: 1.313rem 6rem;
  display: flex;
  height: 100%;
}

.ueber-uns-events-img {
  margin: auto;
}

.ueber-uns-engagement-h3 {
  text-align: left;
  font: normal normal 500 2rem/2.5rem Ubuntu;
}

.ueber-uns-engagement-p {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
}

.ueber-uns-engagement-a {
  text-decoration: underline;
  font-weight: 500;
  color: #fffbf0;
}

.ueber-uns-engagement-a:hover {
  color: #fffbf0;
}

/// UEBERUNS ---end---


/// UEBERUNSMASONRY ---start---
.masonry-container {
  position: relative;
  box-shadow: 3px 5px 12px #00000029;
  border-radius: 4px;
}

.masonry-img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: transparent linear-gradient(0deg, rgba(22, 46, 25, 0.8) 0%, rgba(25, 52, 28, 0.8) 30%, rgba(37, 77, 42, 0.8) 70%, rgba(66, 102, 65, 0.8) 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  z-index: 20;
}

.masonry-img-overlay-custom {
  background: #FAA000 0% 0% no-repeat padding-box;
}

.masonry-overlay-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.masonry-overlay-text-2 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.masonry-img-overlay-custom-hover:hover {
  background: #254D2A 0% 0% no-repeat padding-box;
}


.masonry-overlay-text-heading {
  text-align: center;
  font: normal normal bold 1.5rem Roboto;
  letter-spacing: 0.02rem;
  color: #FFFFFF;
}

.masonry-overlay-text-desc {
  font: normal normal normal 1.125rem Roboto;
  letter-spacing: 0;
  color: #FFFFFF;
}

.masonry-overlay-text-desc-2 {
  padding: 2rem;
  text-align: center;
  font: normal normal bold 1.75rem/2.25rem Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.masonry-container:hover .masonry-img-overlay {
  opacity: 1;
}


.masonry-img {
  width: 100%;
  max-height: 33.125rem;
  border-radius: 4px;
}

.masonry-heading {
  text-align: left;
  font: normal normal bold 3.125rem/3.5rem Ubuntu;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  color: #707070;
}

.masonry-heading-g {
  color: #254D2A !important;
}

/// UEBERUNSMASONRY ---end---


/// LEISTUNGEN ---start---
.leistungen-tab-links {
  width: 25%;
  text-align: center;
  font: normal normal normal 1.313rem/2.125rem Roboto;
  letter-spacing: 0.026rem;
  color: #FFFBF0;
  opacity: 1;
}

.leistungen-compas-tab-btn {
  border: none !important;
  background: none !important;
  text-align: left;
  font: normal normal normal 1.1rem/2.125rem Roboto;
  letter-spacing: 0.026rem;
  color: #FFFBF0;
  opacity: 0.7;
}

.leistungen-compas-tab-btn:hover, .leistungen-compas-tab-btn.active {
  opacity: 1;
  text-decoration: underline;
}

.lesitungen-tab-img-overlay {
  position: absolute;
  bottom: 0;
  background: transparent linear-gradient(360deg, #242424 0%, #575757 100%) 0% 0% no-repeat padding-box;
  opacity: 0.85;
  max-height: 50%;
  width: 100%;
  overflow: auto;
  min-height: 6.25rem;
  cursor: pointer;
}

.lesitungen-tab-img-overlay:hover {
  opacity: 1;
}

.lesitungen-tab-img-text {
  padding: 1rem 8rem 0 8rem;
  text-align: center;
  font: normal normal 500 1.313rem/2.125rem Roboto;
  letter-spacing: 0.026rem;
  color: #FFFBF0;
  opacity: 1;
}

.leistungen-prod-steig-box {
  height: 100%;
  padding: 3rem;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 8px #00000029;
  border-radius: 8px;
}

.leistungen-prod-steig-h2 {
  padding-bottom: 2rem;
  text-align: left;
  font: normal normal normal 2rem/2.313rem Ubuntu;
  letter-spacing: 0px;
  color: #254d2a;
}

.leistungen-prod-steig-p {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  letter-spacing: 0.026rem;
  color: #242424;
}

.leistungen-prod-steig-a {
  text-align: left;
  text-decoration: underline;
  font: normal normal 500 1.313rem/1.875rem Roboto;
  letter-spacing: 0.026rem;
  color: #254d2a;
}

.leistungen-umsetz-ab-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FAA000 0 0 no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  vertical-align: middle;
  font: normal normal bold 2.375rem/1.125rem Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  height: 3.875rem;
}

.leistungen-umsetz-ab-box2 {
  text-align: left;
  font: normal normal 500 1.5rem/2.125rem Roboto;
  color: #242424;
  margin-bottom: auto;
  margin-top: auto;
}

///ARROW-STEPS
.leistungen-breadcrumb-content-bottom-div-1 {
  background: #707070 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
  padding: 1rem;
  left: 0;
}

.leistungen-breadcrumb-content-bottom-div-2 {
  background: #707070 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
  padding: 1rem;
  left: 0;
}

.leistungen-breadcrumb-content-bottom-div-3 {
  background: #707070 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
  padding: 1rem;
  left: 0;
}

ol {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
}

ol > li {
  margin-bottom: 0.8rem;
  padding-left: 1.125rem;
  list-style-type: none;
  counter-increment: item;
}

ol > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

///ARROW-STEPS


.leistungen-arrow-steps-dauer-p {
  text-align: left;
  font: normal normal bold 1.5rem/1.875rem Roboto;
  letter-spacing: 0.015rem;
  color: #242424;
}

.leistungen-arrow-steps-invest-p {
  text-align: left;
  letter-spacing: 0.21px;
  color: #254d2a;
  opacity: 1;
  font: normal normal bold 1.313rem/1.875rem Roboto;
}

.leistungen-arrow-steps-ergebnis-h2 {
  text-align: left;
  font: normal normal bold 1.313rem/1.875rem Roboto;
  letter-spacing: 0.013rem;
  color: #707070;
  opacity: 1;
}


.leistungen-arrow-steps-ergebnis-li {
  text-align: left;
  font: normal normal normal 1.313rem/1.875rem Roboto;
  letter-spacing: 0.013rem;
  color: #242424;
}

.leistungen-arrow-steps-phase {
  text-align: center;
  font: normal normal 500 1.5rem/2.125rem Roboto;
  letter-spacing: 0.078rem;
  color: #ffffff;
  opacity: 1;
}

.leistungen-arrow-steps-container {
  padding: 3.875rem;
}

.leistungen-arrow-steps-main-container {
  height: calc(100% - 100px);;
  width: 100%;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 8px #00000029;
  opacity: 1;
}

/// LEISTUNGEN ---end---


/// ERFOGSGESCHICHTEN ---start---
.col-mw-erfolgsgesch {
  max-width: 35rem;
}

.two-col-erfgesch-h3 {
  text-align: left;
  font: normal normal bold 2rem/2.125rem Ubuntu;
  letter-spacing: 0;
  color: #254D2A;
  opacity: 1;
}

.two-col-erfgesch-p-medium {
  text-align: left;
  font: normal normal 500 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: #707070;
}

.two-col-erfgesch-p-bold {
  text-align: left;
  font: normal normal bold 1.313rem/2.125rem Roboto;
  letter-spacing: 0.013rem;
  color: #254D2A;
}

.two-col-erfgesch-p-span {
  color: #707070 !important;
  font-weight: 500 !important;
}

.two-col-erfgesch-container {
  padding: 3.75rem;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
}

.erfolgsg-h3 {
  margin-bottom: 2.313rem;
  text-align: left;
  font: normal normal normal 2rem/2.813rem Ubuntu;
  letter-spacing: 0px;
  color: #254d2a;
  opacity: 1;
}

.erfolgsg-kbewertung-container1 {
  padding: 5rem 5.625rem;
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 8px #00000029;
  border-radius: 8px;
  opacity: 1;
}

.erfolgsg-kbewertung-container-h3 {
  margin-bottom: 2rem;
  text-align: left;
  font: normal normal bold 2rem/2.125rem Ubuntu;
}

.erfolgsg-kbewertung-container-p {
  text-align: left;
  font: normal normal 500 1.313rem/2.25rem Roboto;
  letter-spacing: 0.013rem;
}

/// ERFOGSGESCHICHTEN ---end---


/// MEDIA-CSS ---start---
@media screen and (max-width: 320px) {
  html {
    font-size: 10px !important;
  }
}

//Mobile devices
@media screen and (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 11px !important;
  }
}

//iPads, Tablets
@media screen and (min-width: 481px) and (max-width: 768px) {
  html {
    font-size: 12px !important;
  }
}

//Small screens, laptops
@media screen and (min-width: 769px) and (max-width: 1024px) {
  html {
    font-size: 13px !important;
  }
}

//Desktops, large screens
@media screen and (min-width: 1025px) and (max-width: 1200px) {
  html {
    font-size: 13px !important;
  }

  .navbar {
    padding: 1rem 0 1rem 6.25rem !important;
  }

  #navbarResponsive .nav-link-kontakt {
    border-radius: 8px 0 0 8px !important;
  }
}

//Extra large screens, TV
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  html {
    font-size: 14px !important;
  }

  .navbar {
    padding: 1rem 0 1rem 6.25rem !important;
  }

  #navbarResponsive .nav-link-kontakt {
    border-radius: 8px 0 0 8px !important;
  }
}

//Extra extra large screens
@media screen and (min-width: 1401px) and (max-width: 1920px) {
  html {
    font-size: 14.6px !important;
  }

  .navbar {
    padding: 1rem 0 1rem 6.25rem !important;
  }

  #navbarResponsive .nav-link-kontakt {
    border-radius: 8px 0 0 8px !important;
  }
}

//Extra extra large screens
@media screen and (min-width: 1921px) {
  html {
    font-size: 16px !important;
  }

  .navbar {
    padding: 1rem 0 1rem 6.25rem !important;
  }

  #navbarResponsive .nav-link-kontakt {
    border-radius: 8px 0 0 8px !important;
  }
}

/// MEDIA-CSS ---end---
